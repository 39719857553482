import priuliInfo from '../../../../assets/imgs/home/05_mice/priuli_info.png';
import priuli from '../../../../assets/imgs/home/05_mice/priuli.webp';
import contariniInfo from '../../../../assets/imgs/home/05_mice/contarini_info.png';
import contarini from '../../../../assets/imgs/home/05_mice/contarini.webp';

interface Feature {
  description: JSX.Element;
  imgPath: string;
}

const PRIULI: Feature = {
  description: (
    <>
      <h2 className='title_underline_light_small warning_text'>Priuli</h2>
      <p>Conference room</p>
      <div className='info_img'>
        <img src={priuliInfo} alt='priuli info' />
      </div>
    </>
  ),
  imgPath: priuli,
};

const CONTARINI: Feature = {
  description: (
    <>
      <h2 className='title_underline_light_small warning_text'>Contarini</h2>
      <p>Conference hall</p>
      <div className='info_img'>
        <img src={contariniInfo} alt='contarini info' />
      </div>
    </>
  ),
  imgPath: contarini,
};

const HallsList: Array<Feature> = [CONTARINI, PRIULI];

export default HallsList;
