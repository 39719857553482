const images = require.context(
  '../../../../assets/imgs/04_restaurants/01_menu',
  true
);
const imageList: Array<string> = images
  .keys()
  .map((image: any) => images(image));

interface Feature {
  description: JSX.Element;
  imgPath: string;
}

const BREAKFAST: Feature = {
  description: (
    <>
      <h2 className='title_underline_complementary_small'>Breakfast</h2>
      <p>
        Whether you're on vacation and getting ready to discover the dynamic
        city center, preparing for a big meeting or just feel like having yummy
        food while hanging out with friends, our breakfast promises a delightful
        experience. Each morning, enjoy the original local tastes and delightful
        authentic delicacies accompanied by great coffee and a selection of
        assorted teas or juices.
      </p>
      <h3 className='title_underline_light_small'>Diocletian's breakfast</h3>
      <p>
        Cornaro Hotel provides you with exquisite tastes of Roman cuisine! Start
        your day with Imperial breakfast, just like Diocletian did, and
        experience the atmosphere of an ancient culture.
      </p>
      <h3 className='title_underline_light_small'>Flavors of Dalmatia</h3>
      <p>
        Dalmatian cuisine owes its popularity to freshness and simplicity, gifts
        of virgin nature and locals who cherish tradition. Indulge your gourmand
        aspirations with famous Dalmatian gastronomy!
      </p>
    </>
  ),
  imgPath: imageList.find((x) => x.includes('breakfast')) || '',
};

const HIGHLIGHTS: Feature = {
  description: (
    <>
      <h2 className='title_underline_complementary_small'>
        Breakfast highlights
      </h2>
      <p>
        Create your own breakfast plate. Besides sumptuous buffet breakfast, we
        are pleased to provide you with a tasty selection of warm made-to-order
        meals, such as crepes, poached eggs and omelets.
      </p>
      <ul>
        <li>Gluten-free choices </li>
        <li>Diocletian's imperial breakfast</li>
        <li>Traditional flavors of Dalmatia </li>
        <li>A selection of homemade honey and marmalades</li>
        <li>Dalmatian spices, herbs, marinades and olive oils</li>
        <li>Freshly made smoothies, milk, yogurts, cereals, seeds, nuts</li>
        <li>Fresh local fruit, including dried figs </li>
        <li>Freshly squeezed homemade juices</li>
        <li>Flavored water enriched with fresh fruit and vegetables</li>
        <li>A selection of local cheeses, hams and prosciutto</li>
        <li>A selection of homemade bread and pastries</li>
        <li>A selection of regional delicacies</li>
        <li>A selection of teas, coffee, homemade liqueurs</li>
      </ul>
    </>
  ),
  imgPath: imageList.find((x) => x.includes('highlights')) || '',
};

const A_LA_CARTE: Feature = {
  description: (
    <>
      <h2 className='title_underline_complementary_small'>A la carte</h2>
      <p>
        With an emphasis on unforgettable combinations, exquisite tastes and
        true freshness of local ingredients, the pleasure of dining is
        complemented by a pleasant setting, sophisticated atmosphere and
        impeccable service.
      </p>
      <p className='warning_text'>
        *Private and formal events can be arranged in our restaurants and
        open-space terraces. For reservations, additional information and
        payment methods, please contact us.
      </p>
    </>
  ),
  imgPath: imageList.find((x) => x.includes('a_la_carte')) || '',
};

const VEGGIE: Feature = {
  description: (
    <>
      <h2 className='title_underline_complementary_small'>Veggie Culinary</h2>
      <p>
        Guests are welcome to enjoy new, creative, vegetarian, and vegan
        selection that will even satisfy the most selective gourmets. We
        recognized a shift in the growing demand of consumers craving healthier
        options and it is our job to cater to the desires of our guests and
        meeting attendees. Cornaro hotel will be able to provide to travelers
        seeking healthy alternatives while on the road starting from healthy
        breakfast choice, gluten free and veggie option with local signature.
      </p>
    </>
  ),
  imgPath: imageList.find((x) => x.includes('veggie')) || '',
};

const WINE: Feature = {
  description: (
    <>
      <h2 className='title_underline_complementary_small'>
        Cornaro wine story
      </h2>
      <p>
        In the most celebrated winemaking region in Croatia, Nikolica vineyards
        spread down the steep slopes of the Pelješac peninsula's southern coast.
        The south-coast wines from Pelješac are so specific that they were among
        the first Croatian wines to be named after the precise village they are
        from, especially Dingač and Postup.
      </p>
      <p>
        Nikolica wines emerged from the centuries-old family tradition of
        growing grapes and producing wine. These ruby red wines are top-notch,
        offering fruity aroma and distinctive bouquet.
      </p>
    </>
  ),
  imgPath: imageList.find((x) => x.includes('wine')) || '',
};

const MenuList: Array<Feature> = [
  BREAKFAST,
  HIGHLIGHTS,
  A_LA_CARTE,
  VEGGIE,
  WINE,
];

export default MenuList;
