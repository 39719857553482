import React, { useState } from 'react';
import Slider from 'react-slick';
import Constants from '../../../../utils/Constants';
import priuliImg from '../../../../assets/imgs/home/05_mice/priuli.webp';
import priuliInfo from '../../../../assets/imgs/home/05_mice/priuli_info.png';
import contariniImg from '../../../../assets/imgs/home/05_mice/contarini.webp';
import contariniInfo from '../../../../assets/imgs/home/05_mice/contarini_info.png';
import ScrollAnimation from '../../../../utils/ScrollAnimation';
import variables from '../../../../style/variables.scss';
import scrollToPage from '../../../../utils/ScrollToLink';
import { useLocation, useNavigate } from 'react-router-dom';

const MiceSection = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const priuli = () => {
    return (
      <>
        <h2 className='title_underline_light_small'>Priuli</h2>
        <p>Conference room</p>
        <div className='info_img'>
          <img src={priuliInfo} alt='priuli info' />
        </div>
      </>
    );
  };

  const contarini = () => {
    return (
      <>
        <h2 className='title_underline_light_small'>Contarini</h2>
        <p>Conference hall</p>
        <div className='info_img'>
          <img src={contariniInfo} alt='contarini info' />
        </div>
      </>
    );
  };

  const [count, setCount] = useState<number>(1);
  const [preventDoubleClick, setPreventDoubleClick] = useState<boolean>(false);
  const transitionSpeed: number = 500;

  const miceTexts: any[] = [contarini, priuli];
  const miceImgs: string[] = [contariniImg, priuliImg];

  const sliderText = React.useRef<Slider | null>(null);
  const sliderImg = React.useRef<Slider | null>(null);

  const nextSlide = () => {
    if (!preventDoubleClick) {
      setPreventDoubleClick(true);

      sliderText.current?.slickNext();
      sliderImg.current?.slickNext();

      if (count === miceImgs.length) {
        setCount(1);
      } else {
        setCount(count + 1);
      }

      setTimeout(function () {
        setPreventDoubleClick(false);
      }, transitionSpeed + 100);
    }
  };

  const prevSlide = () => {
    if (!preventDoubleClick) {
      setPreventDoubleClick(true);

      sliderText.current?.slickPrev();
      sliderImg.current?.slickPrev();

      if (count === 1) {
        setCount(miceImgs.length);
      } else {
        setCount(count - 1);
      }

      setTimeout(function () {
        setPreventDoubleClick(false);
      }, transitionSpeed);
    }
  };

  const slickSettingsOne = {
    adaptiveHeight: true,
    draggable: false,
    swipe: false,
    arrows: false,
    fade: true,
    infinite: true,
    speed: transitionSpeed,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const slickSettingsTwo = {
    adaptiveHeight: false,
    draggable: false,
    swipe: false,
    arrows: false,
    fade: true,
    infinite: true,
    speed: transitionSpeed,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div id='stayingCora' className='mice_section'>
      <div className='mice_section_container container'>
        <ScrollAnimation animateIn='fadeIn' animateOut='fadeOut' duration={100}>
          <ScrollAnimation animateIn='fadeInUp' duration={500} dellay={100}>
            <h2 className='text_center'>MEETING YOUR EXPECTATIONS</h2>
          </ScrollAnimation>
          <ScrollAnimation animateIn='fadeInUp' dellay={150}>
            <p>
              Whether you are hosting an intimate private gathering or a
              corporate event, we have the perfect downtown venues for you. Any
              special occasion can be arranged in a variety of different
              settings, indoors or on our beautiful open terraces.
            </p>
            <div
              className='button'
              onClick={() =>
                scrollToPage(Constants.MICE, location, navigate, true)
              }
            >
              <div className='opacity_transition_low'>SHOW MORE</div>
            </div>
          </ScrollAnimation>
          <div className='mice_section_container_content'>
            <ScrollAnimation animateIn='fadeInUp' dellay={200}>
              <div className='mice_section_container_content_text'>
                <Slider ref={sliderText} {...slickSettingsOne}>
                  {miceTexts.map((x: any) => x())}
                </Slider>
                <div className='mice_section_container_content_buttons'>
                  <div
                    className='opacity_transition_low'
                    onClick={() => prevSlide()}
                  >
                    {Constants.arrowLeft(variables.secondaryColor)}
                  </div>
                  <div
                    className='opacity_transition_low'
                    onClick={() => nextSlide()}
                  >
                    {Constants.arrowRight(variables.secondaryColor)}
                  </div>
                  <div className='mice_section_container_content_buttons_counter'>
                    {count} / {miceTexts.length}
                  </div>
                </div>
              </div>
            </ScrollAnimation>
            <ScrollAnimation animateIn='fadeIn' dellay={250}>
              <div className='mice_section_container_content_img'>
                <Slider ref={sliderImg} {...slickSettingsTwo}>
                  {miceImgs.map((x: string) => (
                    <img
                      src={x}
                      alt={`mice ${miceImgs[miceImgs.indexOf(x)]}`}
                    />
                  ))}
                </Slider>
              </div>
            </ScrollAnimation>
          </div>
        </ScrollAnimation>
      </div>
    </div>
  );
};

export default MiceSection;
