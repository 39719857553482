const arrowWidth = 40;

const HOME = 'home';
const ABOUT = 'about';
const REWARD = 'reward';
const AUTHENTICITY = 'authenticity';
const FEATURES = 'features';
const ACCOMMODATION = 'accommodation';
const OFFERS = 'offers';
const MICE_SECTION = 'mice';
const WELLNESS_SECTION = 'wellness-section';
const GALLERY = 'gallery';
const GLANCE = 'glance';
const FOOTER = 'footer';
const IMPRESSUM = 'impressum';
const RULES = 'rules';
const GREEN_BUSINESS = 'green-business';
const SUSTAINABILITY_POLICY = 'sustainability-policy';
const CERTIFICATES = 'certificates';
const SUSTAINABILITY = 'sustainability';
const RESTAURANTS = 'restaurants';
const RESTAURANT_BAR = 'restaurant-bar';
const MENU = 'menu';
const ENJOY = 'enjoy';
const WELLNESS = 'wellness';
const MICE = 'meetings-and-events';
const MICE_EVENTS = 'mice-events';
const MICE_HALLS = 'mice-halls';

const ACCOMMODATIONS_NAME = 'cornaro';

const COMFORT_ROOM = 'comfort-room';
const SUPERIOR_ROOM = 'superior-room';
const SUPERIOR_CITY_VIEW_ROOM = 'superior-city-view-room';
const DELUXE_ROOM = 'deluxe-room';
const DELUXE_CITY_VIEW_ROOM = 'deluxe-view-room';
const DELUXE_SEA_AND_CITY_VIEW_ROOM = 'deluxe-sea-and-city-view-room';
const JUNIOR_SUITE = 'junior-suite';
const SUPERIOR_SUITE = 'superior-suite';
const DELUXE_SUITE = 'deluxe-suite';
const PREMIUM_SUITE = 'premium-suite';

const arrowLeft = (color: string) => {
  return (
    <svg
      width={arrowWidth}
      height={arrowWidth}
      viewBox='0 0 32 32'
      fill={color}
      xmlns='http://www.w3.org/2000/svg'
      style={{ position: 'relative', top: '1px', width: '100%' }}
    >
      <path d='M32 16C32 12.8355 31.0616 9.74207 29.3035 7.11088C27.5454 4.4797 25.0466 2.42894 22.1229 1.21793C19.1993 0.00693174 15.9823 -0.309921 12.8786 0.307442C9.77486 0.924805 6.92394 2.44866 4.6863 4.6863C2.44866 6.92394 0.924806 9.77486 0.307443 12.8786C-0.309921 15.9823 0.00693096 19.1993 1.21793 22.1229C2.42893 25.0466 4.47969 27.5454 7.11088 29.3035C9.74207 31.0616 12.8355 32 16 32C20.2409 31.9915 24.3056 30.3031 27.3044 27.3044C30.3031 24.3056 31.9915 20.2409 32 16ZM1.28001 16C1.28001 13.0887 2.14332 10.2427 3.76077 7.82201C5.37823 5.40132 7.67718 3.51462 10.3669 2.4005C13.0566 1.28638 16.0163 0.994872 18.8717 1.56285C21.7271 2.13082 24.35 3.53277 26.4086 5.59139C28.4672 7.65002 29.8692 10.2729 30.4372 13.1283C31.0051 15.9837 30.7136 18.9434 29.5995 21.6331C28.4854 24.3228 26.5987 26.6218 24.178 28.2392C21.7573 29.8567 18.9113 30.72 16 30.72C12.0973 30.7158 8.35569 29.1636 5.59607 26.4039C2.83645 23.6443 1.28424 19.9027 1.28001 16ZM15.488 21.888C15.3638 22.0027 15.201 22.0664 15.032 22.0664C14.863 22.0664 14.7002 22.0027 14.576 21.888L9.152 16.448C9.09201 16.3898 9.04431 16.3202 9.01173 16.2432C8.97915 16.1663 8.96237 16.0836 8.96237 16C8.96237 15.9164 8.97915 15.8337 9.01173 15.7568C9.04431 15.6798 9.09201 15.6102 9.152 15.552L14.576 10.112C14.6969 9.99107 14.861 9.92312 15.032 9.92312C15.203 9.92312 15.3671 9.99107 15.488 10.112C15.6089 10.2329 15.6769 10.397 15.6769 10.568C15.6769 10.739 15.6089 10.9031 15.488 11.024L11.152 15.36L22.4 15.36C22.5697 15.36 22.7325 15.4274 22.8526 15.5475C22.9726 15.6675 23.04 15.8303 23.04 16C23.04 16.1697 22.9726 16.3325 22.8526 16.4526C22.7325 16.5726 22.5697 16.64 22.4 16.64L11.152 16.64L15.488 20.976C15.6027 21.1002 15.6664 21.263 15.6664 21.432C15.6664 21.601 15.6027 21.7638 15.488 21.888Z' />
    </svg>
  );
};

const arrowRight = (color: string) => {
  return (
    <svg
      width={arrowWidth}
      height={arrowWidth}
      viewBox='0 0 32 32'
      fill={color}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='M-6.99382e-07 16C-5.61058e-07 19.1645 0.938383 22.2579 2.69649 24.8891C4.45459 27.5203 6.95345 29.5711 9.87706 30.7821C12.8007 31.9931 16.0177 32.3099 19.1214 31.6926C22.2251 31.0752 25.0761 29.5513 27.3137 27.3137C29.5513 25.0761 31.0752 22.2251 31.6926 19.1214C32.3099 16.0177 31.9931 12.8007 30.7821 9.87706C29.5711 6.95344 27.5203 4.45459 24.8891 2.69648C22.2579 0.938382 19.1645 -1.4862e-06 16 -1.34788e-06C11.7591 0.00845522 7.69438 1.69688 4.69563 4.69563C1.69688 7.69438 0.0084555 11.7591 -6.99382e-07 16ZM30.72 16C30.72 18.9113 29.8567 21.7573 28.2392 24.178C26.6218 26.5987 24.3228 28.4854 21.6331 29.5995C18.9434 30.7136 15.9837 31.0051 13.1283 30.4372C10.2729 29.8692 7.65002 28.4672 5.59139 26.4086C3.53276 24.35 2.13081 21.7271 1.56284 18.8717C0.994865 16.0163 1.28637 13.0566 2.40049 10.3669C3.51461 7.67717 5.40131 5.37822 7.822 3.76076C10.2427 2.14331 13.0887 1.28 16 1.28C19.9027 1.28423 23.6443 2.83645 26.4039 5.59606C29.1635 8.35568 30.7158 12.0973 30.72 16ZM16.512 10.112C16.6362 9.9973 16.799 9.9336 16.968 9.9336C17.137 9.9336 17.2998 9.9973 17.424 10.112L22.848 15.552C22.908 15.6102 22.9557 15.6798 22.9883 15.7568C23.0208 15.8337 23.0376 15.9164 23.0376 16C23.0376 16.0836 23.0208 16.1663 22.9883 16.2432C22.9557 16.3202 22.908 16.3898 22.848 16.448L17.424 21.888C17.3031 22.0089 17.139 22.0769 16.968 22.0769C16.797 22.0769 16.6329 22.0089 16.512 21.888C16.3911 21.7671 16.3231 21.603 16.3231 21.432C16.3231 21.261 16.3911 21.0969 16.512 20.976L20.848 16.64L9.6 16.64C9.43026 16.64 9.26747 16.5726 9.14745 16.4525C9.02743 16.3325 8.96 16.1697 8.96 16C8.96 15.8303 9.02743 15.6675 9.14745 15.5474C9.26747 15.4274 9.43026 15.36 9.6 15.36L20.848 15.36L16.512 11.024C16.3973 10.8998 16.3336 10.737 16.3336 10.568C16.3336 10.399 16.3973 10.2362 16.512 10.112Z' />
    </svg>
  );
};

const close = (color: string) => {
  return (
    <svg
      width={arrowWidth}
      height={arrowWidth}
      viewBox='0 0 32 32'
      fill={color}
    >
      <path
        d='M0,16c0,3.2,0.9,6.3,2.7,8.9c1.8,2.6,4.3,4.7,7.2,5.9c2.9,1.2,6.1,1.5,9.2,0.9c3.1-0.6,6-2.1,8.2-4.4s3.8-5.1,4.4-8.2
	c0.6-3.1,0.3-6.3-0.9-9.2c-1.2-2.9-3.3-5.4-5.9-7.2C22.3,0.9,19.2,0,16,0C11.8,0,7.7,1.7,4.7,4.7S0,11.8,0,16z M30.7,16
	c0,2.9-0.9,5.8-2.5,8.2c-1.6,2.4-3.9,4.3-6.6,5.4c-2.7,1.1-5.6,1.4-8.5,0.8c-2.9-0.6-5.5-2-7.5-4s-3.5-4.7-4-7.5
	C1,16,1.3,13.1,2.4,10.4c1.1-2.7,3-5,5.4-6.6c2.4-1.6,5.3-2.5,8.2-2.5c3.9,0,7.6,1.6,10.4,4.3C29.2,8.4,30.7,12.1,30.7,16z
	 M16.9,15.9l5,5C22,21,22,21.1,22,21.3c0,0.2-0.1,0.3-0.2,0.5c-0.1,0.1-0.3,0.2-0.5,0.2c-0.2,0-0.3-0.1-0.5-0.2l-5.4-5.4
	c-0.1-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.2c0-0.1,0-0.2,0-0.2c0-0.1,0.1-0.1,0.1-0.2l5.4-5.4c0.1-0.1,0.3-0.2,0.5-0.2
	s0.3,0.1,0.5,0.2c0.1,0.1,0.2,0.3,0.2,0.5c0,0.2-0.1,0.3-0.2,0.5L16.9,15.9L16.9,15.9z M15.1,15.9l-5-5c-0.1-0.1-0.2-0.3-0.2-0.5
	c0-0.2,0.1-0.3,0.2-0.5c0.1-0.1,0.3-0.2,0.5-0.2c0.2,0,0.3,0.1,0.5,0.2l5.4,5.4c0.1,0.1,0.1,0.1,0.1,0.2c0,0.1,0,0.2,0,0.2
	c0,0.1,0,0.2,0,0.2c0,0.1-0.1,0.1-0.1,0.2L11,21.7c-0.1,0.1-0.3,0.2-0.5,0.2s-0.3-0.1-0.5-0.2c-0.1-0.1-0.2-0.3-0.2-0.5
	c0-0.2,0.1-0.3,0.2-0.5L15.1,15.9L15.1,15.9z'
      />
    </svg>
  );
};

const bookNowLink: string =
  'https://direct-book.com/properties/cornarohoteldirect';

const Constants = {
  arrowWidth,
  HOME,
  ABOUT,
  REWARD,
  AUTHENTICITY,
  FEATURES,
  ACCOMMODATION,
  OFFERS,
  MICE_SECTION,
  WELLNESS_SECTION,
  GALLERY,
  GLANCE,
  FOOTER,
  IMPRESSUM,
  RULES,
  GREEN_BUSINESS,
  SUSTAINABILITY_POLICY,
  CERTIFICATES,
  SUSTAINABILITY,
  RESTAURANTS,
  RESTAURANT_BAR,
  MENU,
  ENJOY,
  WELLNESS,
  MICE,
  MICE_EVENTS,
  MICE_HALLS,
  ACCOMMODATIONS_NAME,
  COMFORT_ROOM,
  SUPERIOR_ROOM,
  SUPERIOR_CITY_VIEW_ROOM,
  DELUXE_ROOM,
  DELUXE_CITY_VIEW_ROOM,
  DELUXE_SEA_AND_CITY_VIEW_ROOM,
  JUNIOR_SUITE,
  SUPERIOR_SUITE,
  DELUXE_SUITE,
  PREMIUM_SUITE,
  arrowLeft,
  arrowRight,
  close,
  bookNowLink,
};

export default Constants;
