const images = require.context(
  '../../../../assets/imgs/01_sustainability/00_greenBusiness',
  true
);
const imageList: Array<string> = images
  .keys()
  .map((image: any) => images(image));

interface Feature {
  description: JSX.Element;
  imgPath: string;
}

const ECO_HOSPITALITY: Feature = {
  description: (
    <>
      <h2 className='title_underline_complementary_small'>ECO-HOSPITALITY</h2>
      <p>
        Cornaro Hotel***** Split complies with EU environmental laws and
        requirements, and seeks to go a step beyond. With our business record,
        water and energy consumption data, which improve continuously our
        facilities and our policy making them more economical.
      </p>
      <p>
        The purpose of our Sustainability Management Plan is to manage both
        long-term decision making and day-to-day business operations in a
        sustainable manner, with environmental, socio-cultural, health and
        safety issues and standards in mind.
      </p>
      <p>
        Cornaro Hotel ***** Split is committed to communicating this plan to our
        partners and our guests.
      </p>
    </>
  ),
  imgPath: imageList.find((x) => x.includes('eco-hospitality')) || '',
};

const CLEAN_AND_SAFE: Feature = {
  description: (
    <>
      <h2 className='title_underline_complementary_small'>
        CLEAN AND SAFE STAY
      </h2>
      <p>
        Daily houskeeping is available on request; guest may choose if they
        don't want their rooms cleaned which means less water and energy usage.
      </p>
      <p>
        All our rooms are smart rooms and Cornaro App is available for all
        services during your entire stay.
      </p>
    </>
  ),
  imgPath: imageList.find((x) => x.includes('clean-and-safe')) || '',
};

const HUMAN_WELLBEING: Feature = {
  description: (
    <>
      <h2 className='title_underline_complementary_small'>
        HUMAN AND HEALTH WELLBEING
      </h2>
      <p>
        We created safe and healthy workplace for our employees with health and
        safety protocols for employees, upgraded cleaning and sanitizing
        protocols, social distancing rules and personal safety equipment and
        protocols followed with trainings and education of our employees. That
        is how we have emphasized our priority in human health and wellbeing of
        both our guests and our employees.
      </p>
    </>
  ),
  imgPath: imageList.find((x) => x.includes('human-wellbeing')) || '',
};

const RECYCLING: Feature = {
  description: (
    <>
      <h2 className='title_underline_complementary_small'>
        RECYCLING & SOLAR PANELS
      </h2>
      <p>
        We've bid farewell to plastic straws, plastic cutlery, plastic cups and
        plastic toothbrushes.
      </p>
      <p>
        We manage plastic, paper and battery waste in an organized manner, which
        leads to energy conservation and recycling.
      </p>
      <p>
        Our goal for 2021 is to have a recycling rate of 90% of all waste. We
        know it's not going to be easy, but we will do our best to achieve this
        goal and you can follow all updates on our socials.
      </p>
      <p>
        Solar cells are installed on our rooftop, wherever possible, to ensure
        greener energy consumption.
      </p>
    </>
  ),
  imgPath: imageList.find((x) => x.includes('recycling')) || '',
};

const GASTRONOMY: Feature = {
  description: (
    <>
      <h2 className='title_underline_complementary_small'>
        GASTRONOMY AND LOCAL TRADITION
      </h2>
      <p>
        In our gastronomy we turn to local community and incorporate local food
        with traditional meals. Our suppliers are domestic producers who use
        fresh, local and seasonal food which means more quality for our guests
        and less waste.
      </p>
      <p>
        All of our meals and snacks on offer follow the calendar to ensure
        maximum freshness of finest domestic ingredients. We offer at least one
        vegetarian, vegan and gluten-free menu option. All our food is
        thoughtfully served with minimised human handling.
      </p>
    </>
  ),
  imgPath: imageList.find((x) => x.includes('gastronomy')) || '',
};

const MEETINGS: Feature = {
  description: (
    <>
      <h2 className='title_underline_complementary_small'>
        MEETINGS AND EVENTS
      </h2>
      <p>
        Once yo've chosen Cornaro, you've elected to stay in a sustainable hotel
        which continually strives to make both the establishment and the planet
        greener. We pride ourselves with:
      </p>
      <ul>
        <li>
          Natural ingredients and materials on offer throughout our property,
          plant and equipment
        </li>
        <li>Minimum usage of disposables</li>
        <li>Seasonal and organic meals and snacks</li>
        <li>Vegetarian, vegan and gluten-free options</li>
        <li>Flavored filter water, organic coffee and tea on offer</li>
        <li>Coffee-break options</li>
        <li>
          Reducing food waste by pre-planning meals with group leaders or
          delegates
        </li>
        <li>Cornaro Sustainable</li>
        <li>Green Meetings Program</li>
      </ul>
    </>
  ),
  imgPath: imageList.find((x) => x.includes('meetings')) || '',
};

const TRANFERS: Feature = {
  description: (
    <>
      <h2 className='title_underline_complementary_small'>
        GREEN TRANFERS & E-CHARGING STATION
      </h2>
      <p>
        Our acclaimed green transport service is focused on your satisfaction
        throughout your stay.
      </p>
      <p>
        Cornaro Hotel provides a charging station for electric vehicles,
        available around the clock every day.
      </p>
    </>
  ),
  imgPath: imageList.find((x) => x.includes('transfers')) || '',
};

const GreenBusinessList: Array<Feature> = [
  ECO_HOSPITALITY,
  CLEAN_AND_SAFE,
  HUMAN_WELLBEING,
  RECYCLING,
  GASTRONOMY,
  MEETINGS,
  TRANFERS,
];

export default GreenBusinessList;
