import ScrollAnimation from '../../../../utils/ScrollAnimation';
import logo from '../../../../assets/imgs/logo_white.svg';
import separator from '../../../../assets/imgs/separator.svg';
import Constants from '../../../../utils/Constants';

const About = () => {
  return (
    <div id={Constants.ABOUT} className='about'>
      <ScrollAnimation animateIn='fadeIn' animateOut='fadeOut' duration={100}>
        <div className='about_container container'>
          <ScrollAnimation animateIn='fadeInUp' delay={100}>
            <div className='about_container_logo'>
              <img src={logo} alt='cornaro logo' />
              <p className='about_container_logo_text'>Wellcome</p>
            </div>
          </ScrollAnimation>
          <div className='about_container_text'>
            <ScrollAnimation animateIn='fadeInUp' delay={150}>
              <h2 className='title_underline_light'>
                HOTEL IN THE CITY, CITY IN THE HOTEL
              </h2>
              <p>
                As an urban lifestyle hotel in the city center, the Cornaro
                Hotel reflects a vibrant atmosphere and pulsing rhythm of the
                ancient city of Split.
              </p>
              <p>
                It's the combination of personal touch, our remarkable staff,
                exceptional location and property full of charm that creates an
                exquisite and authentic Cornaro experience based upon local
                traditions and hospitality.
              </p>
            </ScrollAnimation>
          </div>
        </div>
      </ScrollAnimation>
      <div className='about_container_img'>
        <img src={separator} alt='separator' />
      </div>
    </div>
  );
};

export default About;
