import React, { useEffect, useRef, useState } from 'react';
import Slider from 'react-slick';
import Constants from '../../../../utils/Constants';
import ScrollAnimation from '../../../../utils/ScrollAnimation';
import variables from '../../../../style/variables.scss';
import GreenBusinessList from './GreenBusinessList';

const GreenBusiness = () => {
  const imgRef = useRef<Slider>(null);
  const textRef = useRef<Slider>(null);
  const transitionSpeed: number = 500;

  const [activeSlide, setActiveSlide] = useState<number>(0);

  const next = () => {
    imgRef.current?.slickNext();
    textRef.current?.slickNext();
  };

  const prev = () => {
    imgRef.current?.slickPrev();
    textRef.current?.slickPrev();
  };

  useEffect(() => {
    setActiveSlide(0);
  }, []);

  const slickImg = {
    adaptiveHeight: false,
    draggable: false,
    swipe: false,
    arrows: false,
    fade: true,
    infinite: true,
    speed: transitionSpeed,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const slickText = {
    adaptiveHeight: false,
    responsive: [
      {
        breakpoint: 870,
        settings: {
          adaptiveHeight: true,
        },
      },
    ],
    draggable: false,
    swipe: false,
    arrows: false,
    fade: true,
    infinite: true,
    speed: transitionSpeed,
    slidesToShow: 1,
    slidesToScroll: 1,
    beforeChange: (current: number, next: number) => {
      setActiveSlide(next);
    },
  };

  return (
    <div id={Constants.GREEN_BUSINESS} className='greenBusiness'>
      <div className='greenBusiness_container container'>
        <ScrollAnimation animateIn='fadeIn' animateOut='fadeOut' duration={100}>
          <ScrollAnimation animateIn='fadeInUp' duration={500} dellay={100}>
            <h2 className='text_center'>CORNARO'S GREEN BUSINESS</h2>
            <p>
              Effective sustainability planning, maximizing social and economic
              benefits for the local community, enhancing cultural heritage, and
              reducing negative impacts to the environment have always been our
              utmost concern. Cornaro Sustainable Program is our way of showing
              our guests how we're constantly working on making our hotel
              environmentally-friendly and how much we care about people and the
              planet.
            </p>
          </ScrollAnimation>
          <div className='greenBusiness_container_content greenBusiness_container_content_reverse'>
            <ScrollAnimation animateIn='fadeIn' dellay={150}>
              <div className='greenBusiness_container_content_img'>
                <Slider ref={imgRef} {...slickImg}>
                  {GreenBusinessList.map((feature) => (
                    <img
                      src={feature.imgPath}
                      alt={`${Constants.GREEN_BUSINESS} ${
                        GreenBusinessList[GreenBusinessList.indexOf(feature)]
                      }`}
                    />
                  ))}
                </Slider>
              </div>
            </ScrollAnimation>
            <ScrollAnimation animateIn='fadeInUp' dellay={200}>
              <div className='greenBusiness_container_content_text'>
                <Slider ref={textRef} {...slickText}>
                  {GreenBusinessList.map((feature) => (
                    <>{feature.description}</>
                  ))}
                </Slider>
                <div className='greenBusiness_container_content_buttons'>
                  <div
                    className='opacity_transition_low'
                    onClick={() => prev()}
                  >
                    {Constants.arrowLeft(variables.secondaryColor)}
                  </div>
                  <div
                    className='opacity_transition_low'
                    onClick={() => next()}
                  >
                    {Constants.arrowRight(variables.secondaryColor)}
                  </div>
                  <div className='greenBusiness_container_content_buttons_counter'>
                    {activeSlide + 1} / {GreenBusinessList.length}
                  </div>
                </div>
              </div>
            </ScrollAnimation>
          </div>
        </ScrollAnimation>
      </div>
    </div>
  );
};

export default GreenBusiness;
