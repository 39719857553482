import fitnes from '../../../../assets/documents/02_wellness/Fitness-Wellbeing.pdf';

const images = require.context(
  '../../../../assets/imgs/03_wellnes/00_wellness',
  true
);
const imageList: Array<string> = images
  .keys()
  .map((image: any) => images(image));

interface Feature {
  description: JSX.Element;
  imgPath: string;
  link?: string;
  linkName?: string;
}

const WELLNESS: Feature = {
  description: (
    <>
      <h2 className='title_underline_complementary_small warning_text'>
        WELLNESS
      </h2>
      <p>
        Take a moment for yourself. Serene and discreet, our spa offers an array
        of revitalizing treatments in surroundings that indulge body, mind and
        spirit.
      </p>
      <p>
        During winter period, wellness is open upon request with reduced offer.
      </p>
      <h2 className='title_underline_complementary_small warning_text'>
        FACIAL TREATMENTS
      </h2>
      <p>
        Intensive and comprehensive, our facial treatments will refresh and
        revive your skin leaving remarkable results.
      </p>
      <h2 className='title_underline_complementary_small warning_text'>
        BODY TREATMENTS
      </h2>
      <p>
        These purifying experiences will restore the overall balance of your
        body and mind and ensure deep relaxation.
      </p>
    </>
  ),
  imgPath: imageList.find((x) => x.includes('wellness')) || '',
};

const SPA: Feature = {
  description: (
    <>
      <h2 className='title_underline_complementary_small warning_text'>
        MASSAGES
      </h2>
      <p>
        With a relaxing touch of our professional staff and aromatherapy oils,
        these massages are ideal for reducing stress and restoring the flow of
        energy throughout the body.
      </p>
      <h2 className='title_underline_complementary_small warning_text'>
        STEAM BATH
      </h2>
      <p>
        In the steam bath, the body reacts by opening the pores, relaxing sore
        muscles and improving blood flow.
      </p>
      <h2 className='title_underline_complementary_small warning_text'>
        SAUNA
      </h2>
      <p>
        Our Finnish sauna provides an intimate atmosphere, revitalization and
        numerous health benefits.
      </p>
      <h2 className='title_underline_complementary_small warning_text'>
        JACUZZI
      </h2>
      <p>
        Ideal for laid-back relaxing, our jacuzzi accommodates up to 4 persons.
      </p>
    </>
  ),
  imgPath: imageList.find((x) => x.includes('spa')) || '',
};

const FITNES: Feature = {
  description: (
    <>
      <h2 className='title_underline_complementary_small'>FITNESS</h2>
      <p>
        Our fitness center has everything you need to keep up your routine:
        cardio, strength and stretching equipment.
      </p>
    </>
  ),
  imgPath: imageList.find((x) => x.includes('fitnes')) || '',
  link: fitnes,
  linkName: 'READ MORE',
};

const WellnessComponentList: Array<Feature> = [WELLNESS, SPA, FITNES];

export default WellnessComponentList;
