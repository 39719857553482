import React, { useEffect, useRef, useState } from 'react';
import Slider from 'react-slick';
import Constants from '../../../../utils/Constants';
import ScrollAnimation from '../../../../utils/ScrollAnimation';
import variables from '../../../../style/variables.scss';
import SpaComponentList from './SpaComponentList';

const SpaComponent = () => {
  const imgRef = useRef<Slider>(null);
  const textRef = useRef<Slider>(null);
  const transitionSpeed: number = 500;

  const [activeSlide, setActiveSlide] = useState<number>(0);

  const next = () => {
    imgRef.current?.slickNext();
    textRef.current?.slickNext();
  };

  const prev = () => {
    imgRef.current?.slickPrev();
    textRef.current?.slickPrev();
  };

  useEffect(() => {
    setActiveSlide(0);
  }, []);

  const slickImg = {
    adaptiveHeight: false,
    draggable: false,
    swipe: false,
    arrows: false,
    fade: true,
    infinite: true,
    speed: transitionSpeed,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const slickText = {
    adaptiveHeight: false,
    responsive: [
      {
        breakpoint: 870,
        settings: {
          adaptiveHeight: true,
        },
      },
    ],
    draggable: false,
    swipe: false,
    arrows: false,
    fade: true,
    infinite: true,
    speed: transitionSpeed,
    slidesToShow: 1,
    slidesToScroll: 1,
    beforeChange: (current: number, next: number) => {
      setActiveSlide(next);
    },
  };

  return (
    <div id={Constants.RESTAURANT_BAR} className='wellnessComponents'>
      <div className='wellnessComponents_container container'>
        <ScrollAnimation animateIn='fadeIn' animateOut='fadeOut' duration={100}>
          <ScrollAnimation animateIn='fadeInUp' duration={500} dellay={100}>
            <h2 className='text_center'>PRIVATE SPA SUITE</h2>
            <p>
              Our rituals and massages are for women and men who seek the
              ultimate relaxing ritual and a journey through the traditional
              Roman ancient spa experiences. Back in the day, people used gifts
              of nature to achieve glowing skin, relax muscles and nerve
              tension. Book any of our special offers.
            </p>
          </ScrollAnimation>
          <div className='wellnessComponents_container_content wellnessComponents_container_content_reverse'>
            <ScrollAnimation animateIn='fadeInUp' dellay={150}>
              <div className='wellnessComponents_container_content_text'>
                <Slider ref={textRef} {...slickText}>
                  {SpaComponentList.map((feature) => (
                    <>{feature.description}</>
                  ))}
                </Slider>
                <div className='wellnessComponents_container_content_buttons'>
                  <div
                    className='opacity_transition_low'
                    onClick={() => prev()}
                  >
                    {Constants.arrowLeft(variables.secondaryColor)}
                  </div>
                  <div
                    className='opacity_transition_low'
                    onClick={() => next()}
                  >
                    {Constants.arrowRight(variables.secondaryColor)}
                  </div>
                  <div className='wellnessComponents_container_content_buttons_counter'>
                    {activeSlide + 1} / {SpaComponentList.length}
                  </div>
                </div>
              </div>
            </ScrollAnimation>
            <ScrollAnimation animateIn='fadeIn' dellay={200}>
              <div className='wellnessComponents_container_content_img'>
                <Slider ref={imgRef} {...slickImg}>
                  {SpaComponentList.map((feature) => (
                    <img
                      src={feature.imgPath}
                      alt={`${Constants.RESTAURANT_BAR} ${
                        SpaComponentList[SpaComponentList.indexOf(feature)]
                      }`}
                    />
                  ))}
                </Slider>
              </div>
            </ScrollAnimation>
          </div>
        </ScrollAnimation>
      </div>
    </div>
  );
};

export default SpaComponent;
