const images = require.context(
  '../../../../assets/imgs/04_restaurants/00_restaurantBar',
  true
);
const imageList: Array<string> = images
  .keys()
  .map((image: any) => images(image));

interface Feature {
  description: JSX.Element;
  imgPath: string;
}

const TOMISLAV: Feature = {
  description: (
    <>
      <h2 className='title_underline_complementary_small'>
        Restaurant Kralj Tomislav
      </h2>
      <p>Breakfast: from 06:30 to 10:30</p>
    </>
  ),
  imgPath: imageList.find((x) => x.includes('tomislav')) || '',
};

const NODILO: Feature = {
  description: (
    <>
      <h2 className='title_underline_complementary_small'>Restaurant Nodilo</h2>
      <p>Breakfast: from 06:30 to 10:30</p>
      <p>A la Carte: from 10:30 to 22:00</p>
    </>
  ),
  imgPath: imageList.find((x) => x.includes('nodilo')) || '',
};

const RestaurantBarList: Array<Feature> = [NODILO, TOMISLAV];

export default RestaurantBarList;
