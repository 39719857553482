import Constants from '../../../../utils/Constants';

const images = require.context(
  '../../../../assets/imgs/home/02_features',
  true
);
const imageList: Array<string> = images
  .keys()
  .map((image: any) => images(image));

interface Feature {
  description: JSX.Element;
  imgPath: string;
  link?: string;
}

const STORY: Feature = {
  description: (
    <>
      <h2 className='title_underline_complementary_small'>
        THE STORY BEHIND THE NAME
      </h2>
      <p>
        In the historic center which has been continuously inhabited for more
        than 1700 years, the silent whispers of history, originality of the
        present and warm Mediterranean temperament which lives in the citizens
        are intricately combined.
      </p>
      <p>
        Cornaro Hotel proudly carries the name of the central Baroque bastion,
        and its logotype outlines this silent witness of Split's courage.
      </p>
    </>
  ),
  imgPath: imageList.find((x) => x.includes('story')) || '',
};

const SIGNATURE: Feature = {
  description: (
    <>
      <h2 className='title_underline_complementary_small'>CORNARO SIGNATURE</h2>
      <p>
        Urban, luxury lifestyle hotel in the city center; since 2014. Vibrant
        atmosphere and pulsing rhythm of the ancient city of Split reflects in
        each hotel breath. With its 151 rooms, 2 junior suites, 2 superior, 2
        deluxe and 1 premium suite, the diverse and elegant Cornaro Hotel boasts
        sensible design affiliated with a passion for complete, professional
        service.
      </p>
      <p>
        Perfect spot for modern, educated traveler looking for luxury getaway or
        business guests to welcome their meetings with Cornaro signature.
      </p>
    </>
  ),
  imgPath: imageList.find((x) => x.includes('signature')) || '',
};

const LOCAL: Feature = {
  description: (
    <>
      <h2 className='title_underline_complementary_small'>WE LOVE LOCAL</h2>
      <p>
        From the hotel opening, it was always our intention to create
        state-of-the-art experiences and in order to do that we need to work on
        the development of the product within the destination and create product
        experiences that first, match our destination's DNA.
      </p>
      <p>
        Second, meet high standards for quality, and last but not least, are
        tailored to certain markets or market segments.
      </p>
    </>
  ),
  imgPath: imageList.find((x) => x.includes('local')) || '',
};

const LIFESTYLE: Feature = {
  description: (
    <>
      <h2 className='title_underline_complementary_small'>
        CITY CENTRAL LIFESTYLE HOTLE
      </h2>
      <p>
        The excellent central city location of our hotel, its ancient history
        surroundings and interior elegance of luxurious and comfortable rooms
        make Cornaro Hotel a vital part of the top tourist and business offer of
        the city.
      </p>
      <p>
        Whether it is for work, leisure or simply to refresh your perspective,
        Cornaro Hotel is at your disposal, providing everything you need, from
        warm hospitality to state-of-the-art facilities.
      </p>
    </>
  ),
  imgPath: imageList.find((x) => x.includes('lifestyle')) || '',
};

const RESTAURANTS: Feature = {
  description: (
    <>
      <h2 className='title_underline_complementary_small'>RESTAURANTS</h2>
      <p>
        A single bite defines a destination. Paying tribute to Dalmatia's rich
        culinary heritage, our food is inspired by the abundance of the
        surrounding nature, enticing ingredients and traditional recipes with a
        modern spin.
      </p>
      <p>
        Whether you're on vacation and getting ready to discover the dynamic
        city center, preparing for a big meeting or just feel like having yummy
        food while hanging out with friends, our breakfast promises a delightful
        experience.
      </p>
    </>
  ),
  imgPath: imageList.find((x) => x.includes('restaurants')) || '',
  link: Constants.RESTAURANTS,
};

const ROOFTOOP: Feature = {
  description: (
    <>
      <h2 className='title_underline_complementary_small'>
        SPLIT ROOFTOP BAR & ROOFTOOP JACUZZI
      </h2>
      <p>
        Crowning the hotel on the 6th floor, the Rooftop Bar offers spectacular
        views over the city center and islands. In the summertime, the bar
        invites you to linger over your favorite drink and enjoy live music
        while our chef works the grill magic, serving up dinner specials.
      </p>
      <p>
        Stunning views of the city center, the blue Adriatic and the islands
        provide the perfect backdrop while sipping your favorite drink.
      </p>
    </>
  ),
  imgPath: imageList.find((x) => x.includes('rooftoop')) || '',
};

const TERRACES: Feature = {
  description: (
    <>
      <h2 className='title_underline_complementary_small'>CORNARO TERRACES</h2>
      <p>
        Step into the seductive setting of our Terraces, a warm and welcoming
        space ideal for breakfast followed by morning coffee, afternoon snacks
        or an evening cocktail.
      </p>
      <p>
        With its miniature lemon trees and Mediterranean plants, beautiful open
        terraces represent an essential gathering place which gives you the
        impression of a real Dalmatian atmosphere in the heart of Split.
      </p>
    </>
  ),
  imgPath: imageList.find((x) => x.includes('terraces')) || '',
};

const SPA: Feature = {
  description: (
    <>
      <h2 className='title_underline_complementary_small'>SPA & WELLNESS</h2>
      <p>
        Take a moment for yourself. Serene and discreet, our spa offers an array
        of revitalizing treatments in surroundings that indulge body, mind and
        spirit. Intensive and comprehensive, our facial treatments will refresh
        and revive your skin leaving remarkable results.
      </p>
      <p>
        These purifying experiences will restore the overall balance of your
        body and mind and ensure deep relaxation.
      </p>
    </>
  ),
  imgPath: imageList.find((x) => x.includes('spa')) || '',
  link: Constants.WELLNESS,
};

const TRANSFERS: Feature = {
  description: (
    <>
      <h2 className='title_underline_complementary_small'>
        TRANSFERS AND PARKING
      </h2>
      <p>
        Always leading the way, Cornaro Hotel has installed electric car
        charging point in our car parking as part of our commitment to giving
        customers what they need whilst at the same time promoting better
        environmental practices for the region.
      </p>
      <p>
        The charging service at hotel is available complimentary to all hotel
        guests with parking fee, and helps ensure you enjoy your visit in the
        knowledge that you will not be caught short when wishing to explore the
        city of Split.
      </p>
    </>
  ),
  imgPath: imageList.find((x) => x.includes('transfers')) || '',
};

const MEETINGS: Feature = {
  description: (
    <>
      <h2 className='title_underline_complementary_small'>MEETINGS & EVENTS</h2>
      <p>
        Whether you are hosting an intimate private gathering or a corporate
        event, we have the perfect downtown venues for you. Any special occasion
        can be arranged in a variety of different settings, indoors or on our
        beautiful open terraces.
      </p>
      <p>
        From event planning and welcoming your guests to signature coffee
        breaks, our team is at your service to accompany you through every step
        of your event and enhance your most cherished moments.
      </p>
    </>
  ),
  imgPath: imageList.find((x) => x.includes('meetings')) || '',
  link: Constants.MICE,
};

const SUSTAINABILITY: Feature = {
  description: (
    <>
      <h2 className='title_underline_complementary_small'>SUSTAINABILITY</h2>
      <p>
        Effective sustainability planning, maximizing social and economic
        benefits for the local community, enhancing cultural heritage, and
        reducing negative impacts to the environment have always been our utmost
        concern.
      </p>
      <p>
        Cornaro Sustainable Program is our way of showing our guests how we're
        constantly working on making our hotel environmentally friendly and how
        much we care about people and the planet.
      </p>
    </>
  ),
  imgPath: imageList.find((x) => x.includes('sustainability')) || '',
  link: Constants.SUSTAINABILITY,
};

const FeaturesList: Array<Feature> = [
  STORY,
  SIGNATURE,
  LOCAL,
  LIFESTYLE,
  RESTAURANTS,
  ROOFTOOP,
  TERRACES,
  SPA,
  TRANSFERS,
  MEETINGS,
  SUSTAINABILITY,
];

export default FeaturesList;
