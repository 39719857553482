const images = require.context(
  '../../../../assets/imgs/02_mice/00_events',
  true
);
const imageList: Array<string> = images
  .keys()
  .map((image: any) => images(image));

interface Feature {
  description: JSX.Element;
  imgPath: string;
}

const EXPECTATIONS: Feature = {
  description: (
    <>
      <h2 className='title_underline_complementary_small warning_text'>
        Meeting your expectations
      </h2>
      <p>
        Whether you are hosting an intimate private gathering or a corporate
        event, we have the perfect downtown venues for you. Any special occasion
        can be arranged in a variety of different settings, indoors or on our
        beautiful open terraces.
      </p>
      <p>
        From event planning and welcoming your guests to signature coffee
        breaks, our team is at your service to accompany you through every step
        of your event and enhance your most cherished moments.
      </p>
    </>
  ),
  imgPath: imageList.find((x) => x.includes('expectations')) || '',
};

const STYLISH: Feature = {
  description: (
    <>
      <h2 className='title_underline_complementary_small warning_text'>
        Impress your guests with stylish venues for meetings and events in Split
      </h2>
      <p>
        Schedule a private gathering in a sleek, well-appointed meeting venue or
        reserve our seasonal stylish Split Rooftop Bar with a lounge area that
        is open seasonally from April to October and represents a vibrant place
        for an unforgettable event. The breathtaking Split city skyline makes a
        lovely backdrop for any celebration.
      </p>
      <p>
        If you are hosting a webinar we can offer video production, live
        streaming, projections, video conferences, WiFi and wired Internet, and
        sound with lighting for your event.
      </p>
    </>
  ),
  imgPath: imageList.find((x) => x.includes('stylish')) || '',
};

const FLEXIBLE: Feature = {
  description: (
    <>
      <h2 className='title_underline_complementary_small warning_text'>
        Flexible event venue for your special occasion
      </h2>
      <p>
        We also offer indoor meeting spaces for creative meetings and
        conferences. No matter where you host your event, meeting or conference,
        you can use our complimentary Wi-Fi and enjoy the atmosphere with
        Cornaro signature along with impeccable service.
      </p>
      <p>
        Once you book your event with Cornaro Hotel, give us the details and
        we'll handle the rest. Our Reservation team is at your disposal for any
        further requirements.
      </p>
    </>
  ),
  imgPath: imageList.find((x) => x.includes('flexible')) || '',
};

const EVENTS: Feature = {
  description: (
    <>
      <h2 className='title_underline_complementary_small'>EVENTS</h2>
      <p>
        Whether it's a business occasion, wedding reception or private event,
        our professional team is ready to exceed any expectations. Besides
        conference facilities, the following venues are at your disposal for
        formal or private events:
      </p>
      <ul>
        <li>Restaurants Nodilo and Kralj Tomislav</li>
        <li>Terrace Lounge</li>
        <li>Split Rooftop Bar</li>
        <li>Wine Bar</li>
      </ul>
      <p>
        For reservations, additional information and payment methods, please
        contact us.
      </p>
    </>
  ),
  imgPath: imageList.find((x) => x.includes('events')) || '',
};

const EventsList: Array<Feature> = [EXPECTATIONS, STYLISH, FLEXIBLE, EVENTS];

export default EventsList;
