import Constants from '../../../../utils/Constants';
import confortRoom from '../../../../assets/imgs/home/03_accommodation/comfort_room.webp';
import superirorRoom from '../../../../assets/imgs/home/03_accommodation/superior_room.webp';
import superirorCityViewRoom from '../../../../assets/imgs/home/03_accommodation/superior_city_view_room.webp';
import deluxRoom from '../../../../assets/imgs/home/03_accommodation/deluxe_room.webp';
import deluxeCityViewRoom from '../../../../assets/imgs/home/03_accommodation/deluxe_city_view_room.webp';
import deluxeSeaViewRoom from '../../../../assets/imgs/home/03_accommodation/deluxe_sea_and_city_view_room.webp';
import juniorSuite from '../../../../assets/imgs/home/03_accommodation/junior_suite.webp';
import superiorSuite from '../../../../assets/imgs/home/03_accommodation/superior_suite.webp';
import deluxeSuite from '../../../../assets/imgs/home/03_accommodation/deluxe_suite.webp';
import premiumSuite from '../../../../assets/imgs/home/03_accommodation/premium_suite.webp';

interface Accommodation {
  accommodationtTitle: string;
  accommodationtLink: string;
  accommodationtImage: string;
}

const AccommodationList: Array<Accommodation> = [
  {
    accommodationtTitle: 'Comfort Room',
    accommodationtLink: Constants.COMFORT_ROOM,
    accommodationtImage: confortRoom,
  },
  {
    accommodationtTitle: 'Superior Room',
    accommodationtLink: Constants.SUPERIOR_ROOM,
    accommodationtImage: superirorRoom,
  },
  {
    accommodationtTitle: 'Superior City View Room',
    accommodationtLink: Constants.SUPERIOR_CITY_VIEW_ROOM,
    accommodationtImage: superirorCityViewRoom,
  },
  {
    accommodationtTitle: 'Deluxe Room',
    accommodationtLink: Constants.DELUXE_ROOM,
    accommodationtImage: deluxRoom,
  },
  {
    accommodationtTitle: 'Deluxe City View room',
    accommodationtLink: Constants.DELUXE_CITY_VIEW_ROOM,
    accommodationtImage: deluxeCityViewRoom,
  },
  {
    accommodationtTitle: 'Deluxe Sea and City View Room',
    accommodationtLink: Constants.DELUXE_SEA_AND_CITY_VIEW_ROOM,
    accommodationtImage: deluxeSeaViewRoom,
  },
  {
    accommodationtTitle: 'Junior Suite',
    accommodationtLink: Constants.JUNIOR_SUITE,
    accommodationtImage: juniorSuite,
  },
  {
    accommodationtTitle: 'Superior Suite',
    accommodationtLink: Constants.SUPERIOR_SUITE,
    accommodationtImage: superiorSuite,
  },
  {
    accommodationtTitle: 'Deluxe Suite',
    accommodationtLink: Constants.DELUXE_SUITE,
    accommodationtImage: deluxeSuite,
  },
  {
    accommodationtTitle: 'Premium Suite',
    accommodationtLink: Constants.PREMIUM_SUITE,
    accommodationtImage: premiumSuite,
  },
];

export default AccommodationList;
