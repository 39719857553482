import greenBadge from '../../../../assets/imgs/01_sustainability/02_certificates/green-badge.png';
import reducingBadge from '../../../../assets/imgs/01_sustainability/02_certificates/reducing-badge.png';
import green from '../../../../assets/imgs/01_sustainability/02_certificates/green.png';
import biosphere from '../../../../assets/imgs/01_sustainability/02_certificates/biosphere.png';
import greenBadgePdf from '../../../../assets/documents/00_sustainability/Cornaro Green Attitude.pdf';
import reducingBadgePdf from '../../../../assets/documents/00_sustainability/Reducing Food Waste Tisak.pdf';

interface Certificate {
  img: string;
  title: string;
  pdf?: string;
}

const CertificatesList: Array<Certificate> = [
  {
    img: greenBadge,
    title: 'Cornaro green badge',
    pdf: greenBadgePdf,
  },
  {
    img: reducingBadge,
    title: 'Cornaro reducing badge',
    pdf: reducingBadgePdf,
  },
  {
    img: green,
    title: 'Green certificate',
  },
  {
    img: biosphere,
    title: 'Bisphere certificate',
  },
];

export default CertificatesList;
