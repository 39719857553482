import Constants from '../../../../utils/Constants';
import ScrollAnimation from '../../../../utils/ScrollAnimation';
import CertificatesList from './CertificatesList';

const Certificates = () => {
  return (
    <div id={Constants.CERTIFICATES} className='certificates'>
      <div className='certificates_container container'>
        <ScrollAnimation animateIn='fadeIn' animateOut='fadeOut' duration={100}>
          <div className='certificates_container_tiles'>
            {CertificatesList.map((certificate) => (
              <ScrollAnimation
                animateIn='fadeInUp'
                delay={100 + CertificatesList.indexOf(certificate) * 50}
              >
                <div className='certificates_container_tiles_container'>
                  <div className='certificates_container_tiles_container_img'>
                    {certificate.pdf ? (
                      <a
                        href={certificate.pdf}
                        target='_blank'
                        rel='noreferrer'
                      >
                        <img
                          className='link'
                          src={certificate.img}
                          alt={certificate.title}
                        />
                      </a>
                    ) : (
                      <img src={certificate.img} alt={certificate.title} />
                    )}
                  </div>
                </div>
              </ScrollAnimation>
            ))}
          </div>
        </ScrollAnimation>
      </div>
    </div>
  );
};

export default Certificates;
