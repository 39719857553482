import { useLocation, useNavigate } from 'react-router-dom';
import rooftopImg from '../../../../assets/imgs/home/06_wellness/wellness.jpg';
import Constants from '../../../../utils/Constants';
import ScrollAnimation from '../../../../utils/ScrollAnimation';
import scrollToPage from '../../../../utils/ScrollToLink';

const Wellness = () => {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <div id={Constants.WELLNESS_SECTION} className='wellness_section'>
      <div className='container'>
        <ScrollAnimation animateIn='fadeIn' animateOut='fadeOut' duration={100}>
          <ScrollAnimation
            ScrollAnimation
            animateIn='fadeInUp'
            duration={500}
            delay={100}
          >
            <h2 className='text_center'>WELLNESS</h2>
          </ScrollAnimation>
          <div className='wellness_section_container'>
            <ScrollAnimation animateIn='fadeIn' delay={150}>
              <div className='wellness_section_container_img'>
                <img src={rooftopImg} alt='rooftop bar' />
              </div>
            </ScrollAnimation>
            <ScrollAnimation animateIn='fadeInUp' delay={200}>
              <div className='wellness_section_container_text'>
                <div>
                  <h2 className='title_underline_light_small'>
                    LIVING IN BALANCE
                  </h2>
                  <p>
                    Take a moment for yourself. Serene and discreet, our spa
                    offers an array of revitalizing treatments in surroundings
                    that indulge body, mind and spirit. Let us take you through
                    an array of energizing and relaxing experiences, leaving you
                    feeling nourished and renewed.
                  </p>
                  <div
                    className='button'
                    onClick={() =>
                      scrollToPage(Constants.WELLNESS, location, navigate, true)
                    }
                  >
                    <div className='opacity_transition_low'>SHOW MORE</div>
                  </div>
                </div>
              </div>
            </ScrollAnimation>
          </div>
        </ScrollAnimation>
      </div>
    </div>
  );
};

export default Wellness;
