import { useEffect } from 'react';
import Constants from '../../utils/Constants';
import heroImg from '../../assets/imgs/00_impressum/entrance.jpg';
import { CapitalizeFirstLeter } from '../../utils/CapitalizeFirstLetter';

const Impressum = () => {
  useEffect(() => {
    document.title = `${CapitalizeFirstLeter(Constants.ACCOMMODATIONS_NAME)} - ${CapitalizeFirstLeter(Constants.IMPRESSUM)}`;
  }, []);

  return (
    <div id={Constants.IMPRESSUM} className='impressum'>
      <div className='impressum_hero'>
        <img src={heroImg} alt={`${Constants.IMPRESSUM} hero`} />
      </div>
      <div className='impressum_container container'>
        <h1>{CapitalizeFirstLeter(Constants.IMPRESSUM)}</h1>
        <h3>Property name</h3>
        <p>Cornaro Hotel *****</p>
        <h3>Property address</h3>
        <p>
          <b>Address 1:</b> Sinjska ulica 6
          <br />
          <b>Address 2:</b> Ulica kralja Tomislava 9,
          <br />
          HR-21000 SPLIT
          <br />
          Croatia
        </p>
        <h3>Company name</h3>
        <p>MANAS d.o.o. (LLC)</p>
        <h3>Company address</h3>
        <p>
          Barakovićeva 18
          <br />
          HR-21000 SPLIT
          <br />
          Croatia
        </p>
        <h3>VAT</h3>
        <p>HR77290534017</p>
        <h3>Commercial court</h3>
        <p>
          The company is signed into a court register at Commercial court in
          Split under the number 060086339.
        </p>
        <h3>Phone</h3>
        <p>
          <a href='tel:+385 21 644 200' className='opacity_transition_low'>
            +385 21 644 200
          </a>
          <br />
          <a href='tel:+385 91 644 2000' className='opacity_transition_low'>
            +385 91 644 2000
          </a>
        </p>
        <h3>Email</h3>
        <p>
          <a
            href='mailto:info@cornarohotel.com'
            className='opacity_transition_low'
          >
            info@cornarohotel.com
          </a>
        </p>
      </div>
    </div>
  );
};

export default Impressum;
