import { useEffect } from 'react';

const OnReloadScrollToTop = () => {

  return useEffect(() => {
    window.history.scrollRestoration = 'manual';
  },[]);
};

export default OnReloadScrollToTop;
