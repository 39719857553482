import { useEffect, useState } from 'react';
import Constants from '../../utils/Constants';
import authenticityImg from '../../assets/imgs/home/00_hero/authenticity.jpg';
import helloSplitImg from '../../assets/imgs/home/00_hero/hello_split.jpg';
import experiencePalaceImg from '../../assets/imgs/home/00_hero/experience_a_palace.jpg';
import chooseExperienceImg from '../../assets/imgs/home/00_hero/choose_your_own_experience.jpg';
import unforgettableExperiencesImg from '../../assets/imgs/home/00_hero/unforgettable_experiences.jpg';
import cornaroHotelImg from '../../assets/imgs/home/00_hero/cornaro_hotel.jpg';
import collectImg from '../../assets/imgs/home/00_hero/collect.jpg';
import cornaroHotelSplitImg from '../../assets/imgs/home/00_hero/cornaro_hotel_split.jpg';
import weTravelImg from '../../assets/imgs/home/00_hero/we_travel.jpg';
import exploreImg from '../../assets/imgs/home/00_hero/explore.jpg';
import variables from '../../style/variables.scss';

type HeroItem = {
  imgPath: string;
  title: string;
  subtitle_1: string;
  subtitle_2?: string;
};

const authenticity: HeroItem = {
  imgPath: authenticityImg,
  title: 'Authenticity',
  subtitle_1: 'INTRODUCING NEW GENERATION OF HOSPITALITY',
};

const helloSplit: HeroItem = {
  imgPath: helloSplitImg,
  title: 'HELLO SPLIT!',
  subtitle_1: 'EXPERIENCE CORNARO HOTEL AUTHENTICITY',
  subtitle_2: 'A UNIQUE STAY STARTS IN UNIQUE PLACE',
};

const experiencePalace: HeroItem = {
  imgPath: experiencePalaceImg,
  title: 'CHOOSE YOUR OWN EXPERIENCE',
  subtitle_1: `DON'T JUST SEE, FEEL. DON'T JUST DREAM, DO`,
  subtitle_2: 'LIVE YOUR LIFE',
};

const chooseExperience: HeroItem = {
  imgPath: chooseExperienceImg,
  title: 'EXPERIENCE A PLACE',
  subtitle_1: 'THE BUSTLE OF ITS STREETS AND THE WARMTH OF ITS PEOPLE',
  subtitle_2: 'LET US SHOW YOU A SENSE OF OUR CITY',
};

const weTravel: HeroItem = {
  imgPath: weTravelImg,
  title: 'WE TRAVEL',
  subtitle_1: 'TO FEEL UNIQUE EXPERIENCES',
  subtitle_2: 'TAKE THE RIDE, LIVE THE EXPERIENCE',
};

const cornaroHotel: HeroItem = {
  imgPath: cornaroHotelImg,
  title: 'CORNARO HOTEL',
  subtitle_1: 'MODERN LUXURY LIFESTYLE AWAITS',
};

const unforgettableExperiences: HeroItem = {
  imgPath: unforgettableExperiencesImg,
  title: 'UNFORGETTABLE EXPERIENCES',
  subtitle_1: 'FOREVER MEMORIES',
};

const collect: HeroItem = {
  imgPath: collectImg,
  title: 'COLLECT',
  subtitle_1: 'AND SHARE YOUR MOMENTS OF HAPPINESS',
};

const cornaroHotelSplit: HeroItem = {
  imgPath: cornaroHotelSplitImg,
  title: 'CORNARO HOTEL SPLIT',
  subtitle_1: 'A SENSE OF IDENTITY AND A LIFESTYLE EXPERIENCE',
  subtitle_2: 'A UNIQUE STAY STARTS IN A UNIQUE PLACE',
};

const explore: HeroItem = {
  imgPath: exploreImg,
  title: 'EXPLORE',
  subtitle_1: 'RESERVE YOUR AUTHENTIC CORNARO EXPERIENCE',
};

const heroItemsList: Array<HeroItem> = [
  authenticity,
  helloSplit,
  experiencePalace,
  chooseExperience,
  weTravel,
  cornaroHotel,
  unforgettableExperiences,
  collect,
  cornaroHotelSplit,
  explore,
];

const IMAGE_CONTAINER = 'hero_slideshow_image_container';
const ACTIVE_SLIDE = 'slide_active';
const LAST_SLIDE = 'slide_last';
const TIME_OUT = 5; // duration of transition in seconds

// duplicate first image to the last position
heroItemsList?.push(heroItemsList[0]);

const HeroSlideshow = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const imageContainer = document.getElementById(IMAGE_CONTAINER);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % heroItemsList.length);
    }, TIME_OUT * 1000);

    return () => clearInterval(interval);
  }, []);

  if (imageContainer !== null) {
    setTimeout(
      () => {
        if (currentIndex === heroItemsList.length - 1) {
          imageContainer.classList.add(LAST_SLIDE);
          setCurrentIndex(0);
        } else if (currentIndex === 0) {
          imageContainer.classList.remove(LAST_SLIDE);
        }
      },
      currentIndex === 0 ? 100 : 1100
    );
  }

  return (
    <div id='hero_slideshow'>
      <div
        id={IMAGE_CONTAINER}
        className={ACTIVE_SLIDE}
        style={{
          width: `${heroItemsList.length * 100}vw`,
          transform: `translateX(${-currentIndex * 100}vw)`,
        }}
      >
        {heroItemsList.map((item, index) => (
          <div className='slide'>
            <img
              key={index}
              src={item.imgPath}
              alt={`hero ${Constants.ACCOMMODATIONS_NAME} ${index + 1}`}
            />
            <div
              className={
                index === 0 || index === heroItemsList.length - 1
                  ? 'slide_overlay slide_overlay_special'
                  : 'slide_overlay slide_overlay_normal'
              }
            >
              <h2>{item.title}</h2>
              <h3>{item.subtitle_1}</h3>
              {item.subtitle_2 && <h3>{item.subtitle_2}</h3>}
              {index === 9 && (
                <div className='nav_book'>
                  <a
                    href={Constants.bookNowLink}
                    target='_blank'
                    rel='noreferrer'
                    className='opacity_transition_low'
                  >
                    <div>RESERVE NOW</div>
                  </a>
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default HeroSlideshow;
