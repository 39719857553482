import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Layout from './pages/Layout';
import Home from './pages/home/Home';
import OnReloadScrollToTop from './utils/OnReloadScrollToTop';
import { useEffect } from 'react';
import Impressum from './pages/00_impressum/Impressum';
import Constants from './utils/Constants';
import Sustainability from './pages/02_sustainability/Sustainability';
import Restaurants from './pages/05_restaurants/Restaurants';
import Wellness from './pages/04_wellness/Wellness';
import Mice from './pages/03_mice/Mice';

const App: React.FC = () => {
  let doc: HTMLElement = document.documentElement;

  OnReloadScrollToTop();

  const setHight = () => {
    doc.style.setProperty('--app-height', `${window.innerHeight}px`);
  };

  useEffect(() => {
    setHight();
  }, []);

  window.addEventListener('resize', setHight);

  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Layout />}>
          <Route index element={<Home />} />
          <Route path={Constants.IMPRESSUM} element={<Impressum />} />
          <Route path={Constants.SUSTAINABILITY} element={<Sustainability />} />
          <Route path={Constants.MICE} element={<Mice />} />
          <Route path={Constants.WELLNESS} element={<Wellness />} />
          <Route path={Constants.RESTAURANTS} element={<Restaurants />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default App;
