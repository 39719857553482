import { useEffect } from 'react';
import Constants from '../../utils/Constants';
import heroImg from '../../assets/imgs/01_sustainability/sustainability.webp';
import { CapitalizeFirstLeter } from '../../utils/CapitalizeFirstLetter';
import GreenBusiness from './components/00_greenBusiness/GreenBusiness';
import SustainabilityPolicy from './components/01_sustainabilityPolicy/SustainabilityPolicy';
import Certificates from './components/02_certificates/Certificates';
import brochure from '../../assets/documents/00_sustainability/Green MICE.pdf';

const Sustainability = () => {
  useEffect(() => {
    document.title = `${CapitalizeFirstLeter(
      Constants.ACCOMMODATIONS_NAME
    )} - ${CapitalizeFirstLeter(Constants.SUSTAINABILITY)}`;
  }, []);

  return (
    <div id={Constants.SUSTAINABILITY} className='sustainability'>
      <div className='sustainability_hero'>
        <img src={heroImg} alt={`${Constants.SUSTAINABILITY} hero`} />
        <h1 className='sustainability_hero_title'>
          {CapitalizeFirstLeter(Constants.SUSTAINABILITY)}
        </h1>
      </div>
      <div className='sustainability_container container'>
        <h2>Increasing the value</h2>
      </div>
      <GreenBusiness />
      <SustainabilityPolicy />
      <Certificates />
      <div className='sustainability_subtext container'>
        <h3>
          We recognize and respect the importance of indigenous peoples'
          culture, heritage and traditional rights and we support the
          identification, recording, management and protection of indigenous
          cultural heritage sites.
        </h3>
        <h2>CORNARO SUSTAINABLE GREEN MEETINGS PROGRAM</h2>
        <p>
          Sustainable meeting commitment with clean <br /> safe stay
        </p>
        <a className='opacity_transition_low' href={brochure} target='_blank' rel='noreferrer'>
          SUSTAINABLE GREEN MEETINGS BROCHURE
        </a>
      </div>
    </div>
  );
};

export default Sustainability;
