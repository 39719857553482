import React, { useEffect, useRef, useState } from 'react';
import Slider from 'react-slick';
import Constants from '../../../../utils/Constants';
import ScrollAnimation from '../../../../utils/ScrollAnimation';
import variables from '../../../../style/variables.scss';
import FeaturesList from './FeaturesList';
import { useLocation, useNavigate } from 'react-router-dom';
import scrollToPage from '../../../../utils/ScrollToLink';

const Features = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const transitionSpeed: number = 500;

  const imgRef = useRef<Slider>(null);
  const textRef = useRef<Slider>(null);

  const [activeSlide, setActiveSlide] = useState<number>(0);

  const next = () => {
    imgRef.current?.slickNext();
    textRef.current?.slickNext();
  };

  const prev = () => {
    imgRef.current?.slickPrev();
    textRef.current?.slickPrev();
  };

  useEffect(() => {
    setActiveSlide(0);
  }, []);

  const slickImage = {
    adaptiveHeight: true,
    draggable: false,
    swipe: false,
    arrows: false,
    fade: true,
    infinite: true,
    speed: transitionSpeed,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const slickText = {
    adaptiveHeight: false,
    draggable: false,
    swipe: false,
    arrows: false,
    fade: true,
    infinite: true,
    speed: transitionSpeed,
    slidesToShow: 1,
    slidesToScroll: 1,
    beforeChange: (current: number, next: number) => {
      setActiveSlide(next);
    },
  };

  return (
    <div id={Constants.FEATURES} className='features'>
      <div className='features_container container'>
        <ScrollAnimation animateIn='fadeIn' animateOut='fadeOut' duration={100}>
          <ScrollAnimation animateIn='fadeInUp' duration={500} dellay={100}>
            <h2 className='text_center'>CORNARO KEY FEATURES</h2>
          </ScrollAnimation>
          <div className='features_container_content'>
            <ScrollAnimation animateIn='fadeIn' dellay={150}>
              <div className='features_container_content_img'>
                <Slider ref={imgRef} {...slickText}>
                  {FeaturesList.map((feature) => (
                    <img
                      src={feature.imgPath}
                      alt={`${Constants.FEATURES} ${
                        FeaturesList[FeaturesList.indexOf(feature)]
                      }`}
                    />
                  ))}
                </Slider>
              </div>
            </ScrollAnimation>
            <ScrollAnimation animateIn='fadeInUp' dellay={200}>
              <div className='features_container_content_text'>
                <Slider ref={textRef} {...slickImage}>
                  {FeaturesList.map((feature) => (
                    <>
                      {feature.description}
                      {feature.link && (
                        <div
                          className='button'
                          onClick={() =>
                            scrollToPage(
                              feature.link || '/',
                              location,
                              navigate,
                              true
                            )
                          }
                        >
                          <div className='opacity_transition_low'>
                            SHOW MORE
                          </div>
                        </div>
                      )}
                    </>
                  ))}
                </Slider>
                <div className='features_container_content_buttons'>
                  <div
                    className='opacity_transition_low'
                    onClick={() => prev()}
                  >
                    {Constants.arrowLeft(variables.secondaryColor)}
                  </div>
                  <div
                    className='opacity_transition_low'
                    onClick={() => next()}
                  >
                    {Constants.arrowRight(variables.secondaryColor)}
                  </div>
                  <div className='features_container_content_buttons_counter'>
                    {activeSlide + 1} / {FeaturesList.length}
                  </div>
                </div>
              </div>
            </ScrollAnimation>
          </div>
        </ScrollAnimation>
      </div>
    </div>
  );
};

export default Features;
