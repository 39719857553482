import Constants from '../../../../utils/Constants';
import ScrollAnimation from '../../../../utils/ScrollAnimation';
import rewardPdf from '../../../../assets/documents/home/000_reward/Terrific ten Special offer 2.pdf';

const Reward = () => {
  return (
    <div id={Constants.REWARD} className='reward'>
      <div className='reward_container container'>
        <ScrollAnimation animateIn='fadeIn' animateOut='fadeOut' duration={100}>
          <ScrollAnimation animateIn='fadeInUp' duration={500} delay={100}>
            <h2 className='text_center'>
              TERRIFIC TEN CELEBRATION - CORNARO EXPERIENCE REWARD PROGRAM
            </h2>
          </ScrollAnimation>
          <ScrollAnimation animateIn='fadeInUp' delay={150}>
            <h3 className='title_underline_light_small'>
              Celebrate the decade of excellence with us!
            </h3>
            <a
              href={rewardPdf}
              target='_blank'
              rel='noreferrer'
              className='opacity_transition_low button'
            >
              <div>SHOW MORE</div>
            </a>
          </ScrollAnimation>
        </ScrollAnimation>
      </div>
    </div>
  );
};

export default Reward;
