import { useEffect } from 'react';
import Constants from '../../utils/Constants';
import heroImg from '../../assets/imgs/04_restaurants/restaurants.webp';
import { CapitalizeFirstLeter } from '../../utils/CapitalizeFirstLetter';
import brochure from '../../assets/documents/03_restaurants/Cornaro Gourmet 2023.pdf';
import RestaurantBar from './components/00_restaurantBar/RestaurantBar';
import Menu from './components/01_menu/Menu';
import Enjoy from './components/02_enjoy/Enjoy';
import ScrollAnimation from '../../utils/ScrollAnimation';

const Restaurants = () => {
  useEffect(() => {
    document.title = `${CapitalizeFirstLeter(
      Constants.ACCOMMODATIONS_NAME
    )} - ${CapitalizeFirstLeter(Constants.RESTAURANTS)}`;
  }, []);

  return (
    <div id={Constants.RESTAURANTS} className='restaurants'>
      <div className='restaurants_hero'>
        <img src={heroImg} alt={`${Constants.RESTAURANTS} hero`} />
        <div className='overlay'></div>
        <h1 className='restaurants_hero_title'>
          {CapitalizeFirstLeter(Constants.RESTAURANTS)} & bars
        </h1>
      </div>
      <div className='restaurants_container container'>
        <h2>A STORY OF TRADITION, FLAVORS, BALANCE AND PASSION</h2>
      </div>
      <ScrollAnimation animateIn='fadeInUp' animateOut='fadeOut' duration={500}>
        <div className='restaurants_subtext container'>
          <h3>
            A single bite defines a destination. Paying tribute to Dalmatia's
            rich culinary heritage, our food is inspired by the abundance of the
            surrounding nature, enticing ingredients and traditional recipes
            with a modern spin.
          </h3>
          <a
            className='opacity_transition_low'
            href={brochure}
            target='_blank'
            rel='noreferrer'
          >
            CORNARO GOURMET EXPERIENCE
          </a>
        </div>
      </ScrollAnimation>
      <RestaurantBar />
      <Menu />
      <Enjoy />
    </div>
  );
};

export default Restaurants;
