const images = require.context(
  '../../../../assets/imgs/01_sustainability/01_sustainabilityPolicy',
  true
);
const imageList: Array<string> = images
  .keys()
  .map((image: any) => images(image));

interface Feature {
  description: JSX.Element;
  imgPath: string;
}

const GUARANTEE: Feature = {
  description: (
    <>
      <h2 className='title_underline_complementary_small'>
        We offer and guarantee:
      </h2>
      <ul>
        <li>A safe and healthful workplace</li>
        <li>Environmentally responsible neighbor in community</li>
        <li>Conserving natural resources by reusing and recycling</li>
        <li>Using operational processes that protect the environment</li>
        <li>Responsible use of energy</li>
        <li>
          Participating in efforts to improve environmental protection and
          understanding
        </li>
        <li>
          Taking steps to continually improve environmental performance&nbsp;
        </li>
        <li>Suppliers who promote sound environmental practices</li>
        <li>Enhancing awareness among employees</li>
        <li>Promote sustainability principles to third parties</li>
      </ul>
    </>
  ),
  imgPath: imageList.find((x) => x.includes('guarantee')) || '',
};

const FOCUS: Feature = {
  description: (
    <>
      <h2 className='title_underline_complementary_small'>
        We particularly focus on:
      </h2>
      <ul>
        <li>Equality</li>
        <li>Non-discrimination</li>
        <li>No child labor</li>
        <li>
          Zero tolerance on any type of abuse directed towards any living being
        </li>
        <li>No harsh or degrading treatment/harassment</li>
        <li>No forced / bonded / compulsory labor</li>
        <li>Freedom of association / collective bargaining</li>
        <li>Health and safety</li>
        <li>Working conditions - including working hours</li>
        <li>Fair wages / compensation</li>
        <li>Accessibility for persons with disabilities</li>
        <li>Maternity protection</li>
        <li>Right to strike</li>
        <li>Law compliance</li>
      </ul>
    </>
  ),
  imgPath: imageList.find((x) => x.includes('focus')) || '',
};

const PROUD: Feature = {
  description: (
    <>
      <h2 className='title_underline_complementary_small'>
        We are proud to say we:
      </h2>
      <ul>
        <li>Perform acceptable sustainability standards</li>
        <li>Are locally owned and managed</li>
        <li>Minimize our environmental impact</li>
        <li>Support local community</li>
        <li>
          Protect and do not endanger the resources of the local community
        </li>
        <li>Support local tradition, culture and lifestyle</li>
        <li>Promote the quality of the destination and its authenticity</li>
        <li>Responsibly use local resources</li>
        <li>Support purchase of local products, food and souvenirs</li>
        <li>Contribute to local citizens and community wellbeing</li>
      </ul>
    </>
  ),
  imgPath: imageList.find((x) => x.includes('proud')) || '',
};

const GreenBusinessList: Array<Feature> = [GUARANTEE, FOCUS, PROUD];

export default GreenBusinessList;
