const images = require.context(
  '../../../../assets/imgs/03_wellnes/01_spa',
  true
);
const imageList: Array<string> = images
  .keys()
  .map((image: any) => images(image));

interface Feature {
  description: JSX.Element;
  imgPath: string;
}

const SPA: Feature = {
  description: (
    <>
      <h2 className='title_underline_complementary_small warning_text'>
        CORNARO SPA SUITE
      </h2>
      <p>
        (Private jacuzzi & Finnish sauna)
        <br />
        with a complimentary glass of sparkling wine for two.
        <br /> <br />
        <i>— 50 min for 2 — 63€</i>
      </p>
      <h2 className='title_underline_complementary_small warning_text'>
        MARINE PRELUDE
      </h2>
      <p>
        Cornaro spa suite (Private jacuzzi & Finnish sauna) — 50 min
        <br />
        with a complimentary glass of sparkling wine.
        <br />
        <i>— 60 min — 180€</i>
      </p>
    </>
  ),
  imgPath: imageList.find((x) => x.includes('spa')) || '',
};

const MASSAGE: Feature = {
  description: (
    <>
      <h2 className='title_underline_complementary_small warning_text'>
        RELAX MASSAGE 25/ 50 / 80
      </h2>
      <p>
        Cornaro spa suite (Private jacuzzi & Finnish sauna) — 50 min
        <br />
        with a complimentary glass of sparkling wine.
        <br /> <br />
        <i>— 25 min — 95€ / — 50 min — 145€ / — 80 min — 185€</i>
      </p>
      <h2 className='title_underline_complementary_small warning_text'>
        HOT STONE MASSAGE
      </h2>
      <p>
        Cornaro spa suite (Private jacuzzi & Finnish sauna) — 50 min
        <br />
        with a complimentary glass of sparkling wine.
        <br /> <br />
        <i>— 80 min — 245€</i>
      </p>
      <h2 className='title_underline_complementary_small warning_text'>
        RELAX MASSAGE
      </h2>
      <p>
        Cornaro spa suite (Private jacuzzi & Finnish sauna) — 50 min
        <br />
        with a complimentary glass of sparkling wine.
        <br /> <br />
        <i>— 25 min for 2 — 165€</i>
      </p>
    </>
  ),
  imgPath: imageList.find((x) => x.includes('massage')) || '',
};

const RITUAL: Feature = {
  description: (
    <>
      <h2 className='title_underline_complementary_small warning_text'>
        MERVEILLE ARCTIQUE ENERGISING RITUAL
      </h2>
      <p>
        Cornaro spa suite (Private jacuzzi & Finnish sauna) — 50 min
        <br />
        with a complimentary glass of sparkling wine.
        <br /> <br />
        <i>— 90 min — 295 €</i>
      </p>
      <h2 className='title_underline_complementary_small warning_text'>
        DIOCLETIAN RITUAL
      </h2>
      <p>
        Cornaro spa suite (Private jacuzzi & Finnish sauna) — 50 min
        <br />
        with a complimentary glass of sparkling wine.
        <br /> <br />
        <i>— 80 min — 295 €</i>
      </p>
      <h2 className='title_underline_complementary_small warning_text'>
        JOYAUX ATLANTIQUE - ANTITENSION RITUAL
      </h2>
      <p>
        Cornaro spa suite (Private jacuzzi & Finnish sauna) — 50 min
        <br />
        with a complimentary glass of sparkling wine.
        <br /> <br />
        <i>— 90 min — 295 €</i>
      </p>
    </>
  ),
  imgPath: imageList.find((x) => x.includes('ritual')) || '',
};

const WellnessComponentList: Array<Feature> = [SPA, MASSAGE, RITUAL];

export default WellnessComponentList;
