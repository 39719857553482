import Slider from 'react-slick';
import Constants from '../../../../utils/Constants';
import ScrollAnimation from '../../../../utils/ScrollAnimation';
import OffersList from './OffersList';

const Offers = () => {
  const slickSettingsImgs = {
    focusOnSelect: true,
    infinite: true,
    centerMode: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    dots: true,
    variableWidth: true,
    autoplay: true,
    autoplaySpeed: 5000,
  };

  return (
    <div id={Constants.OFFERS} className='offers'>
      <div className='offers_container container'>
        <ScrollAnimation animateIn='fadeIn' animateOut='fadeOut' duration={100}>
          <ScrollAnimation animateIn='fadeInUp' duration={500} delay={100}>
            <h2 className='text_center'>SPECIAL OFFERS</h2>
          </ScrollAnimation>
          <ScrollAnimation animateIn='fadeIn' delay={200}>
            <Slider {...slickSettingsImgs}>
              {OffersList.map((x) => (
                <div className='offers_container_slider_container'>
                  <div className='offers_container_slider_container_img'>
                    <img src={x.offerImg} alt={x.offerTitle} />
                  </div>
                  <div className='offers_container_slider_container_title'>
                    <h2 className='text_center'>{x.offerTitle}</h2>
                    <a
                      href={x.offerLink}
                      target='_blank'
                      rel='noreferrer'
                      className='opacity_transition_high'
                    >
                      SHOW MORE
                    </a>
                  </div>
                </div>
              ))}
            </Slider>
          </ScrollAnimation>
        </ScrollAnimation>
      </div>
    </div>
  );
};

export default Offers;
