import { useEffect } from 'react';
import Constants from '../../utils/Constants';
import heroImg from '../../assets/imgs/02_mice/mice.webp';
import { CapitalizeFirstLeter } from '../../utils/CapitalizeFirstLetter';
import brochureMice from '../../assets/documents/01_mice/M&E brošura 2023.pdf';
import greenMice from '../../assets/documents/00_sustainability/Green MICE.pdf';
import ScrollAnimation from '../../utils/ScrollAnimation';
import Events from './components/00_events/Events';
import Halls from './components/01_halls/Halls';

const Mice = () => {
  useEffect(() => {
    document.title = `${CapitalizeFirstLeter(
      Constants.ACCOMMODATIONS_NAME
    )} - ${CapitalizeFirstLeter(Constants.MICE)}`;
  }, []);

  return (
    <div id={Constants.MICE} className='mice'>
      <div className='mice_hero'>
        <img src={heroImg} alt={`${Constants.MICE} hero`} />
        <div className='overlay'></div>
        <h1 className='mice_hero_title'>
          {CapitalizeFirstLeter(Constants.MICE)}
        </h1>
      </div>
      <div className='mice_container container'>
        <h2>THE ART OF CORNARO TAILORED MEETINGS AND EVENTS</h2>
      </div>
      <ScrollAnimation animateIn='fadeInUp' animateOut='fadeOut' duration={500}>
        <div className='mice_subtext container'>
          <h3>
            <i>
              "The service is wonderful, the staff is very friendly and
              hospitable and really try to accommodate every wish of the guests.
              The main conference room is spacious and offers a wide range of
              ideas how it can be arranged, the smaller room as well..."
            </i>{' '}
            - World Bank
          </h3>
        </div>
      </ScrollAnimation>
      <Events />
      <Halls />
      <ScrollAnimation animateIn='fadeInUp' animateOut='fadeOut' duration={500}>
        <div className='mice_subtext container'>
          <h2>MEETING & EVENTS FACILITIES</h2>
          <p>
            Sensible design and inspiring environment ideal for planning and
            hosting any event.
          </p>
          <a
            className='opacity_transition_low'
            href={brochureMice}
            target='_blank'
            rel='noreferrer'
          >
            MEETINGS & EVENTS BROCHURE
          </a>
        </div>
      </ScrollAnimation>
      <ScrollAnimation animateIn='fadeInUp' animateOut='fadeOut' duration={500}>
        <div className='mice_subtext container'>
          <h2>CORNARO SUSTAINABLE GREEN MEETINGS PROGRAM</h2>
          <p>
            Sustainable meeting commitment with clean & safe stay.
            <br />
            Stay Cornaro, stay safe!
          </p>
          <a
            className='opacity_transition_low'
            href={greenMice}
            target='_blank'
            rel='noreferrer'
          >
            SUSTAINABLE GREEN MEETINGS BROCHURE
          </a>
        </div>
      </ScrollAnimation>
    </div>
  );
};

export default Mice;
