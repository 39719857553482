import { useEffect } from 'react';
import Constants from '../../utils/Constants';
import heroImg from '../../assets/imgs/03_wellnes/wellness_hero.webp';
import { CapitalizeFirstLeter } from '../../utils/CapitalizeFirstLetter';
import brochure from '../../assets/documents/02_wellness/SPA_MENU.pdf';
import WellnessComponent from './components/00_wellnessComponent/WellnessComponent';
import ScrollAnimation from '../../utils/ScrollAnimation';
import SpaComponent from './components/01_spaSuite/SpaComponent';

const Wellness = () => {
  useEffect(() => {
    document.title = `${CapitalizeFirstLeter(
      Constants.ACCOMMODATIONS_NAME
    )} - ${CapitalizeFirstLeter(Constants.WELLNESS)}`;
  }, []);

  return (
    <div id={Constants.WELLNESS} className='wellness'>
      <div className='wellness_hero'>
        <img src={heroImg} alt={`${Constants.WELLNESS} hero`} />
        <div className='overlay'></div>
        <h1 className='wellness_hero_title'>
          {CapitalizeFirstLeter(Constants.WELLNESS)}
        </h1>
      </div>
      <div className='wellness_container container'>
        <h2>ENJOY YOUR LIFE</h2>
      </div>
      <WellnessComponent />
      <SpaComponent />
      <ScrollAnimation animateIn='fadeInUp' animateOut='fadeOut' duration={500}>
        <div className='wellness_subtext container'>
          <h2>CORNARO HOTEL WELLNESS</h2>
          <p>A place of relaxation, rest and tranquility</p>
          <a
            className='opacity_transition_low'
            href={brochure}
            target='_blank'
            rel='noreferrer'
          >
            SPA DETAILS
          </a>
        </div>
      </ScrollAnimation>
    </div>
  );
};

export default Wellness;
